<template>
  <div>
  <v-dialog v-model="show" max-width="1250px">
    <v-card>
      <button class="v-btn white" id="openX_btn" @click="alert = true">
        &#10006;
      </button>
      <v-card-title class="text-h4">Case Edit</v-card-title>
      <v-alert :value="alert" prominent type="warning">
        <v-row align="center">
          <v-col class="grow">
            This case has not been saved. Would you like to continue without
            saving?
          </v-col>
          <v-col class="shrink">
            <v-btn @click="noSave">Exit Without Saving</v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-dialog v-model="dialogQtrData" max-width="640px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Quarterly Downtime Data (days)</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-if="showQ1"
                    v-model="q1"
                    label="Quarter 1 Downtime"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-if="showQ2"
                    v-model="q2"
                    label="Quarter 2 Downtime"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-if="showQ3"
                    v-model="q3"
                    label="Quarter 3 Downtime"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-if="showQ4"
                    v-model="q4"
                    label="Quarter 4 Downtime"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card-text class="text-h6 ml-n4">
                To unpause components and update component ID numbers click
                <a
                  href="#"
                  @click="openWindow(`/sites/` + siteId + `/inverters_list`)"
                  class="underline"
                  >here</a
                >.
              </v-card-text>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="font-weight-bold text-xs btn-danger bg-gradient-danger"
              @click="dialogQtrData = false"
            >
              Cancel
            </v-btn>
            <v-btn
              class="font-weight-bold text-xs btn-success bg-gradient-success"
              @click="saveQtrData"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-form v-model="valid" ref="newCaseForm">
        <v-container style="padding: 3px">
          <v-row style="padding: 3px">
            <!-- site -->
            <v-col cols="12" style="padding: 3px" md="4">
              <v-autocomplete
                v-model="siteId"
                :items="sites"
                item-text="name"
                item-value="id"
                label="Select Site"
                @change="siteSelect"
                :rules="[(v) => !!v || 'Site is required']"
              ></v-autocomplete>
            </v-col>
            <!-- subject -->
            <v-col cols="12" style="padding: 3px" md="4">
              <v-text-field
                v-model="subject_edit"
                label="Subject"
                required
                :rules="[(v) => !!v || 'Subject is required']"
              ></v-text-field>
            </v-col>
            <!-- case status -->
            <v-col cols="12" style="padding: 3px" md="4">
              <v-autocomplete
                v-model="caseStatus"
                :items="casestatus"
                item-text="name"
                item-value="id"
                label="Select Status"
                :allow-overflow="true"
                @keydown.enter="caseStatusEnter"
                @change="caseStatusChange"
                :rules="[(v) => !!v || 'Status is required']"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <!-- case id -->
            <v-col cols="12" style="padding: 3px" md="4">
              <v-text-field
                v-model="id_edit"
                label="Case ID"
                readonly
              ></v-text-field>
            </v-col>
            <!-- date added -->
            <v-col cols="12" style="padding: 3px" md="4">
              <v-menu
                v-model="date_added_edit"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="Date Added"
                    prepend-icon="event"
                    readonly
                    :value="dateAddedDisp"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="en-in"
                  v-model="dateAddedVal"
                  no-title
                  @input="dateAddedMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" style="padding: 3px" md="4">
              <v-text-field
                v-model="kw_affected_edit"
                label="Kw affected"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- downtime days -->
            <v-col cols="12" style="padding: 3px" md="4">
              <v-text-field
                v-model="downtime_days_edit"
                label="Downtime Days"
                readonly
              ></v-text-field>
            </v-col>
            <!-- monitoring platform -->
            <v-col cols="12" style="padding: 3px" md="4">
              <v-text-field
                v-model="monitoring_plat_edit"
                label="Monitoring Platform"
              ></v-text-field>
            </v-col>
            <!-- downtime pct -->
            <!-- <v-col cols="12" style="padding: 3px;"  md="4">
                <v-text-field
                  v-model="downtime_pct_edit"
                  label="Downtime Pct"
                ></v-text-field>
              </v-col> -->
            <!-- lost production -->
            <v-col cols="12" style="padding: 3px" md="4">
              <v-text-field
                v-model="lost_production_edit"
                label="Lost production"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!--case category -->
            <v-col cols="12" style="padding: 3px" md="4">
              <v-autocomplete
                v-model="caseCategory"
                :items="casecategories"
                item-text="name"
                item-value="id"
                :allow-overflow="true"
                @keydown.enter="caseCategoryEnter"
                @change="caseCategoryChange"
                label="Select Category"
                :rules="[(v) => !!v || 'Category is required']"
              ></v-autocomplete>
            </v-col>
            <!-- case priority -->
            <v-col cols="12" style="padding: 3px" md="4">
              <v-autocomplete
                v-model="casePriority"
                :items="casepriorities"
                :allow-overflow="true"
                @keydown.enter="casePriorityEnter"
                @change="casePriorityChange"
                item-text="name"
                item-value="id"
                label="Select Priority"
                :rules="[(v) => !!v || 'Priority is required']"
              ></v-autocomplete>
            </v-col>
            <!-- service team -->
            <v-col cols="12" style="padding: 3px" md="4">
              <v-autocomplete
                v-model="contact"
                :items="contacts"
                item-text="full_name"
                item-value="id"
                required
                :allow-overflow="true"
                @keydown.enter="contactEnter"
                @change="contactChange"
                label="Select Service Team"
                :rules="[(v) => !!v || 'Contact is required']"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <!-- vendor case num -->
            <v-col cols="12" style="padding: 3px" md="4">
              <v-text-field
                v-model="vendor_case_num_edit"
                label="Vendor Case #"
              ></v-text-field>
            </v-col>
            <!-- address -->
            <v-col cols="12" style="padding: 3px" md="4">
              <v-text-field
                v-model="address_line_1_edit"
                label="Address"
                readonly
              ></v-text-field>
            </v-col>
            <!-- city -->
            <v-col cols="12" style="padding: 3px" md="4">
              <v-text-field v-model="city_edit" readonly label="City">
              </v-text-field>
            </v-col>
            <v-col cols="12" style="padding: 3px" md="4">
              
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" style="padding: 3px" md="6">
              <v-select
                v-model="equipment"
                :items="inverters"
                item-text="name"
                item-value="id"
                label="Equipment Down"
                multiple
                chips
                deletable-chips
                hide-selected
                @change="addEquip"
              >
                <template v-slot:selection="{ attrs, item }">
                  <v-chip
                    v-bind="attrs"
                    close
                    @click:close="removeEquip(item)"
                    >{{ item.name }}</v-chip
                  >
                </template>
              </v-select>
              <div class="text-center">
                <v-dialog persistent v-model="pause_dialog" width="500">
                  <v-card>
                    <v-card-title class="text-h5 lighten-2">
                      Would you like to pause this component?
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="font-weight-bold text-xs btn-danger bg-gradient-danger"
                        @click="noPause"
                      >
                        No
                      </v-btn>
                      <v-btn
                        class="font-weight-bold text-xs btn-success bg-gradient-success"
                        @click="pauseComponent"
                      >
                        Yes
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-col>
            <!-- Multi-Select Contacts -->
            <v-col cols="12" style="padding: 3px" md="6">
              <v-autocomplete
                  v-model="selectedContacts"
                  :items="contacts"
                  item-text="full_name"
                  item-value="id"
                  label="Select Owners"
                  multiple
                  chips
                  deletable-chips
                  hide-selected
                  :search-input.sync="search"
                  clearable
                  @change="handleInputChange"
              >
                <template v-slot:selection="{ attrs, item }">
                  <v-chip
                    v-bind="attrs"
                    close
                    @click:close="removeContact(item)"
                  >
                    {{ item.full_name }}
                  </v-chip>
                </template>
              </v-autocomplete>
              <!-- <v-select
                v-model="selectedContacts"
                :items="contacts"
                item-text="full_name"
                item-value="id"
                label="Select Owners"
                multiple
                chips
                deletable-chips
                hide-selected
                @input="handleInputChange"
              >
                <template v-slot:selection="{ attrs, item }">
                  <v-chip
                    v-bind="attrs"
                    close
                    @click:close="removeContact(item)"
                    >{{ item.full_name }}</v-chip
                  >
                </template>
              </v-select> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="pr-0">
              <work-order
                v-if="this.id_edit && this.edit_item && this.edit_item.site"
                v-bind:case_id="this.id_edit"
                v-bind:subject="this.subject_edit"
                v-bind:date="this.dateAddedVal"
                v-bind:site_name="this.edit_item.site.name"
                v-bind:equipment="this.equipment"
              ></work-order>
            </v-col>
            <v-col cols="6" class="text-left">
              <v-btn
                v-if="this.id_edit != null"
                class="font-weight-bold text-xs btn-success bg-gradient-success"
                @click="openComponentScheduleEdit"
              >
                Update Component Schedule
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <!-- notes -->
            <v-col cols="12" style="padding: 3px" md="12">
              <case-notev-2
                v-if="this.id_edit != null && this.edit_item && this.edit_item.site"
                v-bind:vendor_num="this.vendor_case_num_edit"
                v-bind:subject="this.subject_edit"
                v-bind:case_id="this.id_edit"
                v-bind:site_plans="this.edit_item.site.sitePlans"
                v-bind:system="this.edit_item.site.monitoringSystem"
                v-bind:nativeLink="this.edit_item.site.nativeMonitoringLink"
                v-bind:site_id="this.siteId"
                v-bind:site_name="this.edit_item.site.name"
                v-bind:site_contacts="this.siteContacts"
              ></case-notev-2>
            </v-col>
            <!-- <v-col cols="12" style="padding: 3px;" md="4">
                <v-text-field v-model="notes_edit" label="Notes"></v-text-field>
              </v-col> -->
          </v-row>
          <v-row class="mb-1">
            <v-col>
              <v-btn
                :ripple="false"
                :elevation="0"
                class="font-weight-bold text-xs btn-success bg-gradient-success"
                @click="saveCasePopup"
              >
                Save
              </v-btn>
              <v-btn
                :ripple="false"
                :elevation="0"
                :disabled="!(edit_item && edit_item.id)"
                class="font-weight-bold text-xs btn-success bg-gradient-success ml-1"
                @click="saveCaseClosePopup"
              >
                Save And Close
              </v-btn>
              <v-btn
                v-show="
                  user.claims.user_role == 'super_user' ||
                  user.claims.user_role == 'customer_admin'
                "
                :disabled="!(edit_item && edit_item.id)"
                :ripple="false"
                :elevation="0"
                class="font-weight-bold text-xs btn-default bg-gradient-danger ml-1"
                @click="deleteCase"
              >
                Delete
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
  <!-- component pausing modal -->
  <pausing-modal
    @close="closePausingModal"
    @saveCase="saveCase"
    @saveCloseCase="saveCloseCase"
    @updateDataFromPauseModal="updateDataFromPauseModal"
    :eventToCall="eventToCall"
    v-bind:show_switch="showPausingModal"
    v-bind:site_id="siteId"
  ></pausing-modal>
  </div>
</template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import {
  DropDownList,
  ComboBox,
  AutoComplete,
} from "@progress/kendo-vue-dropdowns";

import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
import Service from "@/services/Service.js";
import CaseNotev2 from "./CaseNotev2.vue";
import WorkOrder from "./WorkOrder.vue";
import PausingModal from "./PausingModal.vue";

export default {
  
components: {
    autocomplete: AutoComplete,
    dropdownlist: DropDownList,
    combobox: ComboBox,
    CaseNotev2,
    WorkOrder,
    PausingModal,
  },
  props: {
    show_switch: Boolean,
    case_id: Number,
    site_id: Number,
    edit_item: Object,
  },
  data: function () {
    return {
      search: '',
      eventToCall: 'saveCase',
      allEquipment: [],
      value: [],
      reporting_groups: [
        { id: 1, name: "foo" },
        { id: 2, name: "bar" },
      ],
      defaultItem: {
        name: "Select Group ...",
        id: 0,
      },
      alert: false,
      allDisabled: true,
      sunDisabled: false,
      customDisabled: false,
      sites: [],
      contacts: [],
      casestatus: [],
      casecategories: [],
      casepriorities: [],
      caseStatus: null,
      caseCategory: null,
      casePriority: null,
      currentInverter: null,
      siteId: 0, //TODO: reset this to 0 after testing
      contact: null,
      defaultItem: {
        name: "Select Site ...",
        id: 0,
      },
      dateAddedMenu: null,
      dateAddedVal: null,
      // dateClosedMenu: null,
      // dateClosedVal: null,
      site_edit_value: "",
      valid: true,
      equipmentType: "inverter",
      equipment: null,
      selectedContacts: [],
      previousContacts: [],
      equipmentName: null,
      inverters: [],
      id_edit: null,
      case_category_edit: null,
      date_added_edit: null,
      // date_closed_edit: null,
      kw_affected_edit: null,
      downtime_days_edit: null,
      downtime_pct_edit: null,
      lost_production_edit: null,
      site_edit: null,
      account_edit: null,
      case_status_edit: null,
      vendor_case_num_edit: null,
      monitoring_plat_edit: null,
      allToggle: 2,
      allCases: null,
      sunovisionCases: null,
      customerCases: null,
      subject_edit: null,
      address_line_1_edit: null,
      city_edit: null,
      visibleDialog: false,
      dialogQtrData: false,
      pause_dialog: false,
      showPausingModal: false,
      showQ1: false,
      showQ2: false,
      showQ3: false,
      showQ4: false,
      q1: null,
      q2: null,
      q3: null,
      q4: null,
      qtr_data: null,
      downtime_numbers: null,
      updatedData: [],
      selectedID: 1,
      selectedField: "selected",
      editID: null,
      sort: [
        { field: "case_status.name", dir: "asc" },
        { field: "case_priority.name", dir: "asc" },
      ],
    }
  },
  watch: {
    case_id(newVal) {
      if (this.case_id) {
        console.log(`case_id was passed: ${newVal}`);
      }
    },
    edit_item(newVal) {
      if (this.edit_item && this.edit_item.id) {
        this.id_edit = this.edit_item.id;
        // console.log(`edit_item changed, newVal ${JSON.stringify(newVal)}`);
        console.log(`edit_item changed, case_id in edit ${newVal.id}`);

        // Call translateCaseOwnersToSelectedContacts here to populate selectedContacts[]
        this.translateCaseOwnersToSelectedContacts(this.edit_item);
      } else {
        console.log(`new case dialog`);
      }
    },
    // Watch for changes in the `show_switch` prop
    show_switch(newVal) {
      console.log(`show_switch ${newVal}`);
      // If the dialog is now open (`true`), then call the validate method
      if (newVal) {
        // if edit_item is null, then reset form values, otherwise load with onRowClick logic
        this.resetFormValues();
        // check to see if this is new or existing case
        if (this.case_id || this.edit_item) {
          this.id_edit = this.edit_item.id;
          this.translateCaseOwnersToSelectedContacts(this.edit_item);
          this.onClick();
        } else if (this.site_id) {
          // also check if we have a site_id to populate, coming from main monitoring portal page
          console.log(`on show_switch, only found site_id = ${this.site_id}, loading up via siteSelect`);
          this.siteId = this.site_id;
          this.siteSelect(this.site_id);
        }
        this.$nextTick(() => {
          if (this.$refs.newCaseForm && typeof this.$refs.newCaseForm.validate === 'function') {
            this.$refs.newCaseForm.validate();
          } else {
            console.error('Form reference or validate method not found.');
          }
        });
      }
    },
  },
  created: function () {
    window.setTimeout(() => {
      this.$refs.newCaseForm.validate();
    }, 1000);
    this.resetFormValues();
    console.log(this.edit_item);
    console.log(`onCreate: site_id = ${this.site_id}`);
    
    this.loadSites();
    this.loadCaseStatus();
    this.loadCaseCategories();
    this.loadCasePriorities();
    this.loadContacts();
  },
  computed: {
    user: function () {
      return this.$store.getters.currentUser;
    },
    dateAddedDisp() {
      return this.dateAddedVal;
    },
    hasItemsInEdit() {
      return this.gridData.filter((p) => p.inEdit).length > 0;
    },
    results: {
      get() {
        return this.componentItems;
      }
    },
    show: {
      get() {
        return this.show_switch;
      },
      set(value) {
        // this.$emit("input", show_switch);
        this.$emit('close', value);
      },
    },
  },
  methods: {
    openDialog() {
      this.showPauseModal = true;
    },
    closeDialog() {
      this.showPauseModal = false;
    },
    openComponentScheduleEdit() {
      this.eventToCall = 'updateDataFromPauseModal';
      this.showPausingModal = true;
    },
    caseCategoryEnter(e) {
      console.log("event");
      const newVal = e.target.value;
      const found = this.casecategories.find((x) => x.name == newVal);
      if (!found) {
        //add to db and list
        Service.createCaseCategory({ name: newVal })
          .then((response) => {
            const newCat = response.data;
            this.casecategories.push(newCat);
            this.caseCategory = newCat;
            Vue.$toast.success(`Category created`, {
              position: "top-right",
            });
          })
          .catch((error) => {
            Vue.$toast.error(`Error loading data`, {
              position: "top-right",
            });
            console.error(error);
          });
      }
    },
    casePriorityEnter(e) {
      const newVal = e.target.value;
      const found = this.casepriorities.find((x) => x.name == newVal);
      if (!found) {
        //add to db and list
        Service.createCasePriority({ name: newVal })
          .then((response) => {
            const newP = response.data;
            this.casepriorities.push(newP);
            this.casePriority = newP;
            Vue.$toast.success(`Priority created`, {
              position: "top-right",
            });
          })
          .catch((error) => {
            Vue.$toast.error(`Error loading data`, {
              position: "top-right",
            });
            console.error(error);
          });
      }
    },
    contactChange(contact_id) {
      const found = this.contacts.find((x) => x.id == contact_id);
      this.contact = found;
    },
    caseStatusChange(case_status_id) {
      var today = new Date();
      const found = this.casestatus.find((x) => x.id == case_status_id);
      const end_date = this.$moment(today).format("YYYY-MM-DD");
      if (found.name == "Done") {
        Service.getCaseDetermineQtr(this.id_edit, this.dateAddedVal, end_date)
          .then((response) => {
            // const qtr_data = response.data;
            this.qtr_data = new Map(Object.entries(response.data));
            this.qtr_data.forEach((value, key) => {
              switch (key) {
                case "1":
                  this.showQ1 = true;
                  // this.q1 = value;
                  this.q1 = value["qty"] ? value["qty"] : 0
                  break;
                case "2":
                  this.showQ2 = true;
                  this.q2 = value["qty"] ? value["qty"] : 0;
                  break;
                case "3":
                  this.showQ3 = true;
                  this.q3 = value["qty"] ? value["qty"] : 0;
                  break;
                case "4":
                  this.showQ4 = true;
                  this.q4 = value["qty"] ? value["qty"] : 0;
                  break;
              }
            });
            this.dialogQtrData = true;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.downtime_numbers = "";
        this.qtr_data = {};
        this.downtime_days_edit = "";
        // this.showDowntimeDays();
      }
      this.caseStatus = found;
    },
    caseCategoryChange(case_category_id) {
      const found = this.casecategories.find((x) => x.id == case_category_id);
      this.caseCategory = found;
    },
    casePriorityChange(case_priority_id) {
      const found = this.casepriorities.find((x) => x.id == case_priority_id);
      this.casePriority = found;
    },
    contactEnter(e) {
      const newVal = e.target.value;
      const found = this.contacts.find((x) => x.name == newVal);
      if(!found){
        let params = { name: newVal }
        Service.createContact(params)
          .then((response) => {
            const newContact = response.data;
            this.contacts.push(newContact);
            this.contact = newContact;
            Vue.$toast.success(`New contact added`, {
              position: "top-right",
            })
          })
          .catch((error) => {
            Vue.$toast.error(`Error adding data`, {
              position: "top-right",
            });
            console.log(error);
          });
      }
    },
    caseStatusEnter(e) {
      const newVal = e.target.value;
      const found = this.casestatus.find((x) => x.name == newVal);
      if (!found) {
        //add to db and list
        Service.createCaseStatus({ name: newVal })
          .then((response) => {
            const newStatus = response.data;
            this.casestatus.push(newStatus);
            this.caseStatus = newStatus;
            Vue.$toast.success(`Status created`, {
              position: "top-right",
            });
          })
          .catch((error) => {
            Vue.$toast.error(`Error loading data`, {
              position: "top-right",
            });
            console.error(error);
          });
      }
    },
    noPause() {
      this.pause_dialog = false;
    },
    pauseComponent() {
      let params = {
        isPaused: true
      }
      this.pause_dialog = false;
      Service.updateInverterPause(this.siteId, this.currentInverter, params)
        .then((response) => {
          console.log(response)
          this.currentInverter = null;
        })
        .catch((error) => {
          Vue.$toast.error(`Error pausing inverter`, { position: "top-right" });
          console.log(error);
        });
    },
    addEquip(value) {
      if (this.id_edit != null) {
        const v = value.slice(-1).pop()
        this.currentInverter = v;
        const z = this.inverters.filter(inverter => value.includes(inverter.id))
        Service.addEquipment(v, this.id_edit, this.equipmentType)
          .then((response) => {
            this.equipment = z
            this.pause_dialog = true;
            console.log('equipment added')
          })
          .catch((error) => {
            Vue.$toast.error(`Error adding equipment`, {
              position: "top-right",
            });
            console.log(error)
          });
      }
    },
    removeEquip(item) {
      if (this.id_edit != null) {
        Service.removeEquipment(item.id, this.id_edit)
          .then((response) => {
            console.log('equipment ' + item.id + ' removed')
            this.equipment.forEach(obj => {
              if (obj.id === item.id) {
                this.equipment.splice(this.equipment.indexOf(item), 1)
                this.equipment = [...this.equipment]
              }
            })
          })
          .catch((error) => {
            Vue.$toast.error(`Error removing equipment`, {
              position: "top-right",
            });
            console.log(error)
          });
      } else {
        this.equipment.forEach(obj => {
          if (obj === item.id) {
            this.equipment.splice(this.equipment.indexOf(obj), 1)
          }
        });
      }
    },
    handleInputChange(newContactIds) {
      // Get only the IDs from previousContacts for comparison
      const previousContactIds = this.previousContacts.map(
        (contact) => contact
      );

      // Find added contacts (new in newContacts but not in previousContacts)
      const addedContacts = newContactIds.filter(
        (contactId) => !previousContactIds.includes(contactId)
      );

      // Find removed contacts (present in previousContacts but not in newContacts)
      const removedContacts = this.previousContacts.filter(
        (contact) => !newContactIds.includes(contact)
      );

      // Handle added contact
      if (addedContacts.length > 0) {
        console.log(`Adding contact(s):`, addedContacts);
        addedContacts.forEach((contact_id) => {
          this.addContact(contact_id);
        });
      }

      // Handle removed contact
      if (removedContacts.length > 0) {
        console.log(`Removing contact(s):`, removedContacts);
        removedContacts.forEach((contact) => {
          this.removeContact(contact);
        });
      }

      // Update the previousContacts list after changes are made
      // this.previousContacts = [...newContacts];
      // After handling add/remove, clear the search input
      this.search = ""; // This clears the input after an option is selected
    },
    addContact(value) {
      if (this.id_edit != null) {
        console.log(`adding contact ${value}`);

        const caseOwnerData = {
          case_id: this.id_edit,
          contact_id: value, // Assuming `value` is the contact_id
        };

        // Call the Service to add the case owner
        Service.addCaseOwner(caseOwnerData)
          .then((response) => {
            console.log("Contact added successfully:", response.data);
            Vue.$toast.success("Contact added successfully", {
              position: "top-right",
            });
            console.log(`set previous contacts: ${this.selectedContacts}`);
            // Update the `previousContacts` list after successful addition
            this.previousContacts = [...this.selectedContacts];
          })
          .catch((error) => {
            Vue.$toast.error("Error adding contact", {
              position: "top-right",
            });
            console.error("Error adding contact:", error);
          });
      } else {
        // Update the `previousContacts` list after successful addition
        this.previousContacts = [...this.selectedContacts];
      }
    },

    removeContact(contact) {
      if (this.id_edit != null) {
        console.log(`Removing contact with id: ${contact.id} and case ${this.id_edit}`);

        // Call the Service to remove the case owner
        Service.removeCaseOwner(contact.id, this.id_edit)
          .then(() => {
            console.log(`Contact ${contact.id} removed successfully`);
            Vue.$toast.success(`Contact removed successfully`, {
              position: "top-right",
            });
            console.log(this.selectedContacts);
            // Remove the contact ID from `selectedContacts`
            const index = this.selectedContacts.findIndex(
              (c) => c === contact.id
            );
            if (index !== -1) {
              this.selectedContacts.splice(index, 1);
            }

            // Update `previousContacts` to keep them in sync with `selectedContacts`
            console.log(`set previous contacts: ${this.selectedContacts}`);

            this.previousContacts = [...this.selectedContacts];
          })
          .catch((error) => {
            Vue.$toast.error(`Error removing contact`, {
              position: "top-right",
            });
            console.error(`Error removing contact:`, error);
          });
      } else {
        // new case, just splice list
        // Remove the contact ID from `selectedContacts`
        const index = this.selectedContacts.findIndex((c) => c === contact.id);
        if (index !== -1) {
          this.selectedContacts.splice(index, 1);
        }

        // Update `previousContacts` to keep them in sync with `selectedContacts`
        console.log(`set previous contacts: ${this.selectedContacts}`);

        this.previousContacts = [...this.selectedContacts];
      }
    },
    noSave() {
      // this.show_switch = false;
      this.alert = false;
      // send cancel or close event
      this.$emit('cancel');
    },
    loadContacts() {
      Service.getContacts()
        .then((response) => {
          this.contacts = response.data;
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading data`, {
            position: "top-right",
          });
          console.error(error);
        });
    },
    loadCaseStatus() {
      Service.getCaseStatus()
        .then((response) => {
          this.casestatus = response.data;
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading data`, {
            position: "top-right",
          });
          console.error(error);
        });
    },
    pauseSite() {
      let params = {
        isPaused: true
      }
      this.save_dialog = false;
      Service.updateSite(this.siteId, params)
        .then((response) => {
          console.log(response);
          this.getData();
          this.saveCase();
        })
        .catch((error) => {
          Vue.$toast.error(`Error pausing inverter`, { position: "top-right" });
          console.log(error);
        });
    },
    pauseSiteClose() {
      let params = {
        isPaused: true
      }
      this.save_close_dialog = false;
      Service.updateSite(this.siteId, params)
        .then((response) => {
          console.log(response);
          this.getData();
          this.saveCloseCase();
        })
        .catch((error) => {
          Vue.$toast.error(`Error pausing inverter`, { position: "top-right" });
          console.log(error);
        });
    },
    loadCaseCategories() {
      Service.getCaseCategories()
        .then((response) => {
          this.casecategories = response.data;
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading data`, {
            position: "top-right",
          });
          console.error(error);
        });
    },
    loadCasePriorities() {
      Service.getCasePriorities()
        .then((response) => {
          this.casepriorities = response.data;
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading data`, {
            position: "top-right",
          });
          console.error(error);
        });
    },
    loadSites() {
      Service.getAllSites()
        .then((response) => {
          this.sites = response.data.filter(site => 
            (site.id === site.masterSiteId) || site.masterSiteId === null
          );
          if (this.site_id) {
            // Here we've created the component and a site_id has been passed, so we need to load it as default
            console.log(
              `on create, after loadSites, found site_id = ${this.site_id}, loading up via siteSelect`
            );
            this.siteId = this.site_id;
            this.siteSelect(this.site_id);
          }
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading sites data`, {
            position: "top-right",
          });
          console.error(error);
        });
    },
    siteSelect(site_id) {
      // update address and city
      const site = this.sites.find((x) => x.id == site_id);
      this.address_line_1_edit = site.address.street;
      this.city_edit = site.address.city;
      this.monitoring_plat_edit = site.monitoringSystem;
      Service.getInvertersGridAllMaster(site.id)
        .then((response) => {
          this.inverters = response.data;
          // NProgress.done();
        })
        .catch((error) => {
          console.log(error);
          // NProgress.done();
        });
    },
    showDowntimeDays() {
      this.downtime_days_edit = "";
      this.qtr_data.forEach((value, key) => {
        switch (key) {
          case "1":
            const val1 = this.qtr_data.get("1")["qty"];
            this.downtime_days_edit += "Q1: " + val1;
            break;
          case "2":
            const val2 = this.qtr_data.get("2")["qty"];
            this.downtime_days_edit += " Q2: " + val2;
            break;
          case "3":
            const val3 = this.qtr_data.get("3")["qty"];
            this.downtime_days_edit += " Q3: " + val3;
            break;
          case "4":
            const val4 = this.qtr_data.get("4")["qty"];
            this.downtime_days_edit += " Q4: " + val4;
            break;
        }
      });
    },
    toggleDialog() {
      this.show = !this.show;
      // this.show_switch = !this.show_switch;
    },
    filterChange: function (ev) {
      this.filter = ev.filter;
    },
    deleteCase() {
      const event = {
        dataItem: this.edit_item,
      };
      // this.remove(event);
      this.$emit('remove', event)
    },
    saveCasePopup() {
      // if existing case (i.e. edit_item and the id is populated, which is the case id), then call save function, otherwise then we show pause modal. 
      if(this.edit_item && this.edit_item.id) { 
        console.log(`existing case ${this.edit_item.id}`);
        this.saveCase(null);
      } else { 
        console.log('not found');
        this.eventToCall = 'saveCase';
        this.showPausingModal = true;
      }
    },
    saveCaseClosePopup() {
      // if existing case (i.e. edit_item and the id is populated, which is the case id), then call save function, otherwise then we show pause modal. 
      if(this.edit_item && this.edit_item.id) { 
        console.log(`existing case ${this.edit_item.id}`);
        this.saveCloseCase(null);
      } else { 
        console.log('not found');
        this.eventToCall = 'saveCloseCase';
        this.showPausingModal = true;
      }
    },
    translateCaseOwnersToSelectedContacts(caseData) {
      // TODO: need to read this from API, case_owner data from main case array could be stale
      // Assuming 'case_owners' contains the list of owners for the case
      // const caseOwners = caseData.case_owners;

      Service.getCaseOwners(caseData.id)
        .then((response) => {
          console.log("Case owners:", response.data);
          const caseOwners = response.data.data;
          // Process the data as needed
          // Map 'case_owners' to their 'contact_id' values and load them into selectedContacts
          this.selectedContacts = caseOwners ? caseOwners.map(caseOwner => caseOwner.contact_id) : [];

          // Store the initial set of selected contact IDs into 'previousContacts'
          this.previousContacts = [...this.selectedContacts];
        })
        .catch((error) => {
          console.error("Error fetching case owners:", error);
        });
    },
    updateDataFromPauseModal(payload) {
      console.log(payload);
      if (payload && payload.kwAffectedSum && payload.kwAffectedSum > 0) {
        this.kw_affected_edit = payload.kwAffectedSum;
      }
    },
    closePausingModal() {
      this.showPausingModal = false;
    },
    saveCase(payload) {
      // check if payload in call from modal
      if (payload && payload.kwAffectedSum && payload.kwAffectedSum > 0) {
        this.kw_affected_edit = payload.kwAffectedSum;
      }
      this.save_dialog = false;
      console.log("Save Case...");
      // const updatedData = this.gridData.slice();
      const item = {}; // this.update(updatedData, this.edit_item);

      // make sure and set case id here if in edit mode... not create
      if(this.edit_item && this.edit_item.id) {
        item.id = this.edit_item.id;
      }
      item.subject = this.subject_edit;
      item.date_added = this.dateAddedVal;
      // item.date_closed = this.dateClosedVal;

      //feature/583
      console.log('check if empty', item.kw_affected);
      if (this.kw_affected_edit != null) {
        item.kw_affected = this.kw_affected_edit;
      } else {
        item.kw_affected = 0;
      }

      // item.downtime_days = this.downtime_days_edit;

      //feature/687
      // We don't want to save downtime_pct from UI
      // if (this.downtime_pct_edit != null) {
      //   item.downtime_pct = parseFloat(this.downtime_pct_edit).toFixed(2);
      // } else {
      //   item.downtime_pct = 0;
      // }

      //feature/583
      if (this.lost_production_edit != null) {
        item.lost_production = this.lost_production_edit;
      } else {
        item.lost_production = 0;
      }

      item.site_id = this.siteId;
      if (item.site) {
        item.site.name = this.sites.find((s) => s.id == this.siteId).name;
      }

      // status
      if (this.caseStatus) {
        item.case_status = this.caseStatus;
        item.case_status_id = this.caseStatus.id;
      }

      // category
      if (this.caseCategory) {
        item.case_category = this.caseCategory;
        item.case_category_id = this.caseCategory.id;
      }

      // priority
      if (this.casePriority) {
        item.case_priority = this.casePriority;
        item.case_priority_id = this.casePriority.id;
      }

      if (this.contact) {
        item.contact = this.contact;
        item.contact_id = this.contact.id;
      }

      item.vendor_case_num = this.vendor_case_num_edit;
      // item.notes = this.notes_edit;
      item.address_line_1 = this.address_line_1_edit;
      item.city = this.city_edit;

      //feature/583
      item.downtime_numbers = this.downtime_numbers;
      // sum qtr days in downtime_days var

      // add case_owners
      // Map over selectedContacts to return full contact objects
      let case_owners = this.selectedContacts
        .map((contact_id) => {
          // Find the contact object from the contacts array based on contact_id
          return this.contacts.find((contact) => contact.id === contact_id);
        })
        .filter((contact) => contact !== undefined); // Filter out any undefined contacts

      //feature/583
      if (this.sumDowntimeNumbers(item.downtime_numbers) != null) {
        item.downtime_days = this.sumDowntimeNumbers(item.downtime_numbers);
      } else {
        item.downtime_days = 0;
      }

      // check equip
      let equip = {};
      if (this.equipment != null) {
        equip = this.equipment.map((e) => {
          return { equipment_id: e, equipment_type: "inverter" };
        });
      }
      // item.contact = e.target.value;
      // this.gridData = updatedData;
      const event = {
        dataItem: item,
      };

      this.$emit("save", event, equip, case_owners);
      // this.save(event);
      // if (this.$route.params.id) {
      //   this.toggleDialog();
      // }
    },
    saveCloseCasePopup() {
      this.save_close_dialog = true;
    },
    saveCloseCase(payload) {
      // check if payload in call from modal
      if(payload && payload.kwAffectedSum && payload.kwAffectedSum > 0) {
        this.kw_affected_edit = payload.kwAffectedSum;
      }
      this.save_close_dialog = false;
      console.log("Save Case...");
      const item = {}; 
      
      // make sure and set case id here if in edit mode... not create
      if(this.edit_item && this.edit_item.id) {
        item.id = this.edit_item.id;
      }
      item.subject = this.subject_edit;
      item.date_added = this.dateAddedVal;
      // item.date_closed = this.dateClosedVal;

      // remove downtime_pct as it is calculated on the server side
      delete item.downtime_pct;
      //feature/583
      if (this.kw_affected_edit != null) {
        item.kw_affected = this.kw_affected_edit;
      } else {
        item.kw_affected = 0;
      }

      // item.downtime_days = this.downtime_days_edit;
      //feature/583
      // if (this.downtime_pct_edit != null) {
      //   item.downtime_pct = parseFloat(this.downtime_pct_edit).toFixed(2);
      // } else {
      //   item.downtime_pct = 0;
      // }

      //feature/583
      if (this.lost_production_edit != null) {
        item.lost_production = this.lost_production_edit;
      } else {
        item.lost_production = 0;
      }

      item.site_id = this.siteId;
      if (item.site) {
        item.site.name = this.sites.find((s) => s.id == this.siteId).name;
      }

      // status
      if (this.caseStatus) {
        item.case_status = this.caseStatus;
        item.case_status_id = this.caseStatus.id;
      }

      // category
      if (this.caseCategory) {
        item.case_category = this.caseCategory;
        item.case_category_id = this.caseCategory.id;
      }

      // priority
      if (this.casePriority) {
        item.case_priority = this.casePriority;
        item.case_priority_id = this.casePriority.id;
      }

      if (this.contact) {
        item.contact = this.contact;
        item.contact_id = this.contact.id;
      }

      item.vendor_case_num = this.vendor_case_num_edit;
      // item.notes = this.notes_edit;
      item.address_line_1 = this.address_line_1_edit;
      item.city = this.city_edit;

      //feature/583
      item.downtime_numbers = this.downtime_numbers;

      // sum qtr days in downtime_days var
      //feature/583
      if (this.sumDowntimeNumbers(item.downtime_numbers) != null) {
        item.downtime_days = this.sumDowntimeNumbers(item.downtime_numbers);
      } else {
        item.downtime_days = 0;
      }

      // check equip
      let equip = {};
      if (this.equipment != null) {
        equip = this.equipment.map((e) => {
          return {equipment_id: e, equipment_type: "inverter"}
        });
      }
      // add case_owners
      // Map over selectedContacts to return full contact objects
      let case_owners = this.selectedContacts
        .map((contact_id) => {
          // Find the contact object from the contacts array based on contact_id
          return this.contacts.find((contact) => contact.id === contact_id);
        })
        .filter((contact) => contact !== undefined); // Filter out any undefined contacts

      // item.contact = e.target.value;
      // this.gridData = updatedData;
      const event = {
        dataItem: item,
      };

      this.$emit("saveAndClose", event, equip, case_owners);

      this.toggleDialog();
      this.alert = false;
      this.equipment = null;
      
      // this.id_edit = null;
      // this.$emit("close");
      // TODO: figure out why we need this??
      // if (this.$route.params.id) {
      //   this.$router.push("/cases/");
      // }
    },
    onClickOutside() {
      this.toggleDialog();
    },
    viewAll() {
      this.allDisabled = true;
      this.sunDisabled = false;
      this.customDisabled = false;
      this.gridData = null;
      this.gridData = this.allCases;
    },
    viewSunovision() {
      this.allDisabled = false;
      this.sunDisabled = true;
      this.customDisabled = false;
      this.gridData = null;
      this.gridData = this.sunovisionCases;
    },
    viewCustomer() {
      this.allDisabled = false;
      this.sunDisabled = false;
      this.customDisabled = true;
      this.gridData = null;
      this.gridData = this.customerCases;
    },
    getLostProduction(case_id) {
      Service.getCaseLostProduction(case_id)
        .then((response) => {
          this.lost_production_edit = response.data.lost_production;
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
        });
    },
    saveQtrData() {
      // iterator over qtr data, save inputs and push to db
      this.qtr_data.forEach((value, key) => {
        switch (key) {
          case "1":
            if (this.q1 != null) {
              const val1 = this.q1;
              this.qtr_data.get("1")["qty"] = val1;
            } else {
              this.qtr_data.get("1")["qty"] = 0;
            }
            break;
          case "2":
            if (this.q2 != null) {
              const val2 = this.q2;
              this.qtr_data.get("2")["qty"] = val2;
              console.log(val2);
            } else {
              this.qtr_data.get("2")["qty"] = 0;
            }
            break;
          case "3":
            if (this.q3 != null) {
              const val3 = this.q3;
              this.qtr_data.get("3")["qty"] = val3;
            } else {
              this.qtr_data.get("3")["qty"] = 0;
            }
            break;
          case "4":
            if (this.q4 != null) {
              const val4 = this.q4;
              this.qtr_data.get("4")["qty"] = val4;
            } else {
              this.qtr_data.get("4")["qty"] = 0;
            }
            break;
        }
      });
      // make call
      this.downtime_numbers = Object.fromEntries(this.qtr_data);
      this.showDowntimeDays();
      this.dialogQtrData = false;
    },
    sumDowntimeNumbers(downtime_numbers) {
      var sum = 0;
      if (downtime_numbers) {
        const dn = new Map(Object.entries(downtime_numbers));
        console.log(dn);
        dn.forEach((value, key) => {
          sum += parseInt(dn.get(key)["qty"], 10);
        });
      }

      return sum;
    },
    loadCaseFromSearch(array) {
      let caseIdFromSearch = this.$route.params.id;
      var lookup = {};
      for (var i = 0, len = array.length; i < len; i++) {
        lookup[array[i].id] = array[i];
      }

      this.edit_item = lookup[caseIdFromSearch];
      this.id_edit = lookup[caseIdFromSearch].id;
      this.subject_edit = lookup[caseIdFromSearch].subject;
      this.siteId = lookup[caseIdFromSearch].site.id;
      this.caseStatus = lookup[caseIdFromSearch].case_status;
      this.caseCategory = lookup[caseIdFromSearch].case_category;
      this.casePriority = lookup[caseIdFromSearch].case_priority;
      this.date_added_edit = lookup[caseIdFromSearch].date_added;
      this.dateAddedVal = this.$moment(
        lookup[caseIdFromSearch].date_added
      ).format("YYYY-MM-DD");
      this.dateClosedVal = this.$moment(
        lookup[caseIdFromSearch].date_closed
      ).format("YYYY-MM-DD");
      this.kw_affected_edit = lookup[caseIdFromSearch].kw_affected;
      this.downtime_pct_edit = lookup[caseIdFromSearch].downtime_pct;
      this.lost_production_edit = lookup[caseIdFromSearch].lost_production;
      this.contact = lookup[caseIdFromSearch].contact;
      this.vendor_case_num_edit = lookup[caseIdFromSearch].vendor_case_num;
      if (this.edit_item.site) {
        this.monitoring_plat_edit = this.edit_item.site.monitoringSystem;
        this.address_line_1_edit = this.edit_item.site.address.street;
        this.city_edit = this.edit_item.site.address.city;
      }

      this.toggleDialog();
    },
    onClick() {
      // this.selectedID = event.dataItem.id;
      // set edit item
      // this.resetFormValues();
      // this.edit_item = event.dataItem;

      this.subject_edit = this.edit_item.subject;
      this.siteId = this.edit_item.site.id;
      this.caseStatus = this.edit_item.case_status;
      this.caseCategory = this.edit_item.case_category;
      this.casePriority = this.edit_item.case_priority;
      // this.date_added_edit = this.edit_item.date_added;
      this.dateAddedVal = this.$moment(this.edit_item.date_added).format(
        "YYYY-MM-DD"
      );
      this.dateClosedVal = this.$moment(this.edit_item.date_closed).format(
        "YYYY-MM-DD"
      );
      // this.date_closed_edit = this.edit_item.date_closed;
      this.kw_affected_edit = this.edit_item.kw_affected;
      // this.downtime_days_edit = this.edit_item.downtime_days;
      this.downtime_pct_edit = this.edit_item.downtime_pct;
      this.lost_production_edit = this.edit_item.lost_production;
      this.contact = this.edit_item.contact;
      // this.equipment = this.edit_item.equipment;
      // lookup address/city from site id
      // const site = this.sites.find(s => s.id == this.siteId)

      this.vendor_case_num_edit = this.edit_item.vendor_case_num;
      if (this.edit_item.site) {
        this.monitoring_plat_edit = this.edit_item.site.monitoringSystem;
        this.address_line_1_edit = this.edit_item.site.address.street;
        this.city_edit = this.edit_item.site.address.city;
      }
      let site_id = this.siteId
      Service.getContactRelationBySite(this.siteId)
        .then((response) => {
          this.siteContacts = response.data
          console.log("here they are", this.siteContacts)
        })
        .catch((error) => {
          console.log(error)
        });
      Service.getInvertersGridAllMaster(site_id)
        .then((response) => {
          this.inverters = response.data;
          this.allEquipment = this.edit_item.equipment
          const c = this.edit_item.equipment.map((b) => {
            return b.equipment_id
          });
          this.equipment = this.inverters.filter(inverter => c.includes(inverter.id))
          // this.equipment = b.find(obj => {
          //    return obj === d
          // })
          console.log(this.equipment)
          // NProgress.done();
        })
        .catch((error) => {
          // NProgress.done();
          console.log(error);
        });
      console.log(`selected ${this.selectedID}`);
      // this.getLostProduction(this.id_edit);
      // this.toggleDialog();
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort;
      this.gridData = orderBy(this.gridData, this.sort);
    },
    getRandomInt() {
      return Math.floor(Math.random() * 10000);
    },
    dataStateChange: function (event) {
      console.log("dataStateChange...");
    },

    pageChange(event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
    itemChange: function (e) {
      if (e.dataItem.id) {
        let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
        let updated = Object.assign({}, this.gridData[index], {
          [e.field]: e.value,
        });
        this.gridData.splice(index, 1, updated);
      } else {
        e.dataItem[e.field] = e.value;
      }
    },
    resetFormValues() {
      var date = new Date();
      // this.toDateVal = this.$moment(date).format('YYYY-MM-DD');
      // this.edit_item = null;
      this.id_edit = null;
      this.subject_edit = null;
      this.siteId = null;
      this.caseStatus = null;
      this.caseCategory = null;
      this.casePriority = null;
      this.date_added_edit = null;
      this.dateAddedVal = this.$moment(date).format("YYYY-MM-DD");
      // this.dateClosedVal = this.$moment(date).format("YYYY-MM-DD");
      // this.date_closed_edit = null;
      this.kw_affected_edit = null;
      this.downtime_days_edit = null;
      this.downtime_pct_edit = null;
      this.lost_production_edit = null;

      this.contact = null;
      this.vendor_case_num_edit = null;
      this.city_edit = null;
      this.address_line_1_edit = null;
      // reset qtr switches
      this.showQ1 = false;
      this.showQ2 = false;
      this.showQ3 = false;
      this.showQ4 = false;
      this.equipment = [];
      this.selectedContacts = [];
      this.previousContacts = [];
    },
    
    update(data, item, remove) {
      let updated;
      let caseID = item.id;
      let index = data.findIndex(
        (c) =>
          JSON.stringify({ ...c }) === JSON.stringify(item) ||
          (caseID && c.id === caseID)
      );
      if (index >= 0) {
        updated = Object.assign({}, item);
        data[index] = updated;
      } else {
        let id = 0;
        updated = Object.assign({}, item, { id: id });
        data.unshift(updated);
        index = 0;
      }

      if (remove) {
        data = data.splice(index, 1);
      }
      return data[index];
    },
    cancel(e) {
      if (e.dataItem.id) {
        let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
        let updated = Object.assign(this.gridData[index], {
          inEdit: undefined,
        });
        this.gridData.splice(index, 1, updated);
      } else {
        let index = this.gridData.findIndex(
          (p) => JSON.stringify(e.dataItem) === JSON.stringify(p)
        );

        this.gridData.splice(index, 1);
      }
    },
    remove(e) {
      e.dataItem.inEdit = undefined;
      Service.deleteCase(e.dataItem.id)
        .then((response) => {
          this.toggleDialog();
          Vue.$toast.warning(`Case removed`, {
            position: "top-right",
            duration: 4000,
          });
          this.update(this.gridData, e.dataItem, true);
        })
        .catch((error) => {
          Vue.$toast.error(`Error removing data`, { position: "top-right" });
          console.log(error);
        });
    },
    cancelChanges() {
      let editedItems = this.gridData.filter((p) => p.inEdit === true);
      if (editedItems.length) {
        editedItems.forEach((item) => {
          item.inEdit = undefined;
        });
      }
    },
    openWindow: function (link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style>
</style>